module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142793023-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Hemp Sheet","short_name":"THS","start_url":"/","background_color":"#fffbf6","theme_color":"#69a900","display":"standalone","icon":"src/assets/images/logo-crest-1024.png","legacy":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
